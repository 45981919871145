<template>
  <div class="wrap">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="hint" v-html="hint()" />
    <NuxtLink v-if="linkHref && linkLabel" :to="linkHref" class="link">
      {{ linkLabel }}
    </NuxtLink>
  </div>
</template>

<script>
  export default {
    props: {
      linkHref: {
        type: String,
        default: null,
      },
      linkLabel: {
        type: String,
        default: null,
      },
    },
    methods: {
      hint() {
        return this.$slots.default()[0]?.children
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wrap {
    @apply text-xs leading-normal;
  }
  .hint {
    color: $gray-text;
  }
  .link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
